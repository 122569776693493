import React from "react"
import Layout from "../../components/layout";
import Promo from "../../components/promo";
import Feature from "../../components/feature";
import Title from "../../components/title";
import { Helmet } from 'react-helmet';
import { Link } from "gatsby";

import Schedule from "../../../static/icons/schedule.inline.svg";
import Targets from "../../../static/icons/targets.inline.svg";
import Reminders from "../../../static/icons/reminders.inline.svg";
import Projects from "../../../static/icons/projects.inline.svg";
import Email from "../../../static/icons/email.inline.svg";
import warnings from "../../../static/product/warnings.svg";
import Arrow from "../../../static/icons/arrow.inline.svg";

const Product = ({ location }) => (
    
    <Layout 
      path={location.pathname}>

      <Helmet
        bodyAttributes={{
          class: 'bg-root text-white'
        }} />

      <Title
        sub="Schedule"
        colour="blue-500"
        title="Say goodbye to your calendar."
        width="max-w-md">

        <p className="max-w-xl leading-relaxed mx-auto my-8 text-light text-center text-lg md:text-xl">
          Planning is key to being sucessful freelancer, and it all starts with your schedule.
          Slate makes it easy for you to block out time on client work, see when you're next free, and alert 
          you if you're going to overbook yourself.
        </p>

        <Link to="https://app.heyslate.com/register" className="max-w-xl mx-auto flex items-center justify-center space-x-2 text-accent md:text-lg mt-6">
          <span clasName="text-accent">Get started for free</span>
          <Arrow/>
        </Link>

      </Title>

      <div className="lg:container max-w-6xl mx-auto md:text-lg">

        <div className="mac">

          <div className="relative pb-16/9 rounded-lg overflow-hidden">
            <video className="outline-none absolute w-full h-full object-cover" autoPlay muted loop playsinline>
              <source src="/videos/feature_schedule.mp4" type="video/mp4" />
            </video>
          </div>

        </div>

        <div className="lg:flex lg:space-x-8">

          <div className="lg:w-1/2 space-y-4 sm:space-y-8">

            <Feature 
              icon={<Schedule />}
              title="A calendar for freelancers."
              text="Check your schedule in a simple chronological list that can be scrolled infinitely, forwards and back. 
              Move things around by dragging work from day to day, or add new work with a simple click."
              tight={false}
              colour="blue-500" />

            <Feature 
              icon={<Targets />}
              title="Block out time super fast."
              text="Click one (or more) days to add work to your schedule. If the work is for a project you can create one
              at the same time, or add it to an existing project."
              tight={false}
              colour="blue-500" />

            <Feature 
              icon={<Email />}
              title="Weekly summaries."
              text="Get an email at the end of every week reminding you what you've worked on and how much you earnt,
              and an email at the beginning of every week remdining you of what's coming up."
              tight={false}
              colour="blue-500" />

          </div>

          <div className="lg:w-1/2 space-y-4 sm:space-y-8">

            <Feature 
              icon={<Reminders />}
              title="Early warning system."
              text="Being dependent on one client can be devastating if they suddently stop working with you. 
              Slate warns you if you're becoming too reliant on one client so you can start looking
              for others to work with."
              tight={true}
              image={warnings}
              colour="blue-500" />

            <Feature 
              icon={<Projects />}
              title="Autofill your schedule."
              text="If you have a project that's going to take multiple days, Slate will scan your schedule for days you're free 
              and book them out so you don't overbook yourself."
              tight={false}
              colour="blue-500" />

          </div>
          
        </div>

      </div>

      <Promo theme="dark" />

    </Layout>
)

export default Product;